<mat-toolbar class="header">
  <span class="text-body-secondary text-uppercase fs-5 exo-font fw-600">

    <img src="../../../assets/images/esos-logo.png" [routerLink]="['/dashboard']" width="130"
      class="me-3 pe-3 border-end pointer" alt="ESOS Batch Checker">
    {{ title }}
  </span>
  <span class="example-spacer"></span>
  <span class="header-button d-none d-lg-flex">
    <button mat-stroked-button color="primary" matTooltip="Dashboard" routerLink="/dashboard"
      routerLinkActive="active"><mat-icon aria-hidden="false" aria-label="Home">dashboard</mat-icon> Dashboard</button>
    <button mat-stroked-button color="primary" matTooltip="Automated Processing" routerLink="/automated-processing"
      routerLinkActive="active" class="btn-icon"><span class="material-symbols-outlined"> autoplay </span>Automated
      Processing</button>
    <button matTooltip="Admin Features"
      *ngIf="authService.getUserRole() === userTypeList.Admin || authService.getUserRole() === userTypeList.SuperUser"
      mat-stroked-button color="primary" routerLink="/admin" routerLinkActive="active" class="btn-icon"><span
        class="material-symbols-outlined"> demography </span>ADMIN FEATURES</button>
  </span>
  <span class="example-spacer"></span>
  <button mat-button color="primary" matTooltip="About" routerLink="/about" class="d-none d-lg-flex"><span
      class="material-symbols-outlined me-2 text-body-tertiary"> demography </span>About</button>
  <button mat-button [matMenuTriggerFor]="menu" class="user-btn d-none d-lg-flex">
    <span class="material-symbols-outlined">
      <img src="../../../assets/images/avatar.svg" class="me-1" alt="User">
    </span> {{name}} <span class="material-symbols-outlined"> arrow_drop_down </span>
  </button>
  <button mat-icon-button class="example-icon d-flex d-lg-none user-btn" aria-label="Menu" (click)="snav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>

  <mat-menu #menu="matMenu" xPosition="before" class="profile-menu">
    <!-- <button mat-menu-item> -->
    <button mat-menu-item aria-label="logout" (click)="logout()"><mat-icon
        color="primary">logout</mat-icon>Logout</button>
  </mat-menu>
</mat-toolbar>

<mat-drawer-container autosize hasBackdrop="over" class="mob-navigation">
  <mat-drawer #snav class="sidenav" mode="over">
    <div class="user-btn d-flex bg-primary py-2 px-3 text-white align-items-center">
      <span class="material-symbols-outlined">
        <img src="../../../assets/images/avatar.svg" class="me-1" alt="User">
      </span> {{name}}


      <button mat-icon-button class="example-icon d-flex d-lg-none user-btn ms-auto" aria-label="Menu" color="accent"
        (click)="snav.toggle()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <mat-list role="list">
      <mat-list-item role="listitem"><button matTooltip="Dashboard" mat-button color="primary" routerLink="/dashboard"
          routerLinkActive="active"><mat-icon aria-hidden="false" class="me-2"
            aria-label="Home">dashboard</mat-icon>Dashboard</button></mat-list-item>
      <mat-list-item role="listitem">
        <button mat-button matTooltip="Automated Processing" color="primary" routerLink="/automated-processing"
          routerLinkActive="active" class="btn-icon">
          <span class="material-symbols-outlined m-0 me-1">
            autoplay
          </span>
          Automated Processing
        </button>
      </mat-list-item>
      <mat-list-item role="listitem"
        *ngIf="authService.getUserRole() === userTypeList.Admin || authService.getUserRole() === userTypeList.SuperUser">
        <button mat-button color="primary" matTooltip="Admin Features" routerLink="/admin" routerLinkActive="active"
          class="btn-icon">
          <span class="material-symbols-outlined m-0 me-1">demography
          </span>ADMIN FEATURES
        </button>
      </mat-list-item>
    </mat-list>
    <hr>
    <mat-list role="list">
      <mat-list-item role="listitem">
        <button mat-button color="primary" (click)="logout()">
          <mat-icon color="primary">logout</mat-icon>Logout
        </button>
      </mat-list-item>


    </mat-list>
  </mat-drawer>
</mat-drawer-container>