import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { AuthService } from '../../services/auth.service';
import { UserRoles } from '../../models/enum';
import { CommonModule } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { UserDetailsService } from '../../services/user-details-service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MatToolbarModule, MatButtonModule, MatTooltip, MatIconModule, MatMenuModule, RouterOutlet, RouterLink, RouterLinkActive, MatSidenavModule, MatListModule, CommonModule],
  templateUrl: './header.component.html'
})


export class HeaderComponent implements OnInit {
  title = 'Batch Checker';
  name: string | null = '';
  // public roleList = Roles;
  public userTypeList = UserRoles;
  constructor(public authService: AuthService, private userDetailsService: UserDetailsService,) {
  }

  ngOnInit(): void {
    this.name = this.userDetailsService.getItems()?.userName ??'';
  }

  logout() {
    this.authService.logout();
  }

}